<template>
  <div class="studentSituation">
    <div class="studentSituation-cont-left">
      <div class="studentSituation-cont-title">学生情况</div>
      <div class="stu-cont">
        <div class="stu-cont-item">
          <img src="../../assets/img/stu-num.png" alt="">
          <div class="stu-common">学生总人数</div>
          <div class="stu-num">{{stuStatusData.totalPeople}}</div>
        </div>
        <div class="stu-cont-item">
          <img src="../../assets/img/pay.png" alt="">
          <div class="stu-common">缴费人数</div>
          <div class="stu-num">{{stuStatusData.payCount}}</div>
        </div>
        <div class="stu-cont-item">
          <img src="../../assets/img/noPay.png" alt="">
          <div class="stu-common">未缴费人数</div>
          <div class="stu-num">{{stuStatusData.unpaidAmountCount}}</div>
        </div>
      </div>
    </div>
    <div class="studentSituation-cont-right">
      <v-chart class="chart" :option="option" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'studentSituation',
  data() {
    return {
      stuStatusData: {},
      option: {
        // title: {
        //   show:true,
        //   text: '学生数据情况'
        // },
        legend: {},
        tooltip: {
          trigger: 'axis'
        },
        dataset: {
          source: [
            ['product'],
            ['学生总人数'],
            ['已缴费人数'],
            ['未缴费人数']
          ]
        },
        xAxis: { type: 'category' },
        yAxis: { gridIndex: 0 },
        // grid: { top: '55%' },
        series: [
          {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
            itemStyle:{
              normal:{
                color:'#1A7EE2', //折点颜色
                lineStyle:{
                  color:'#1A7EE2' //折线颜色#FEBC24
                }
              }
            }
          },
          {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
            itemStyle:{
              normal:{
                color:'#FEBC24', //折点颜色
                lineStyle:{
                  color:'#FEBC24' //折线颜色
                }
              }
            }
          },
          {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
            itemStyle:{
              normal:{
                color:'#F64E60', //折点颜色
                lineStyle:{
                  color:'#F64E60' //折线颜色
                }
              }
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.getStuTotalData();
    this.getYearTotalList();
  },
  methods: {
    getStuTotalData() {
      this.$request.getStuTotal().then(res => {
        if(res.data.code == 0) {
          this.stuStatusData = res.data.data;
        }
      })
    },
    getYearTotalList() {
      this.$request.getYearTotalList().then(res => {
        if(res.data.code == 0) {
          let data = res.data.data;
          for(let key in data) {
            this.option.dataset.source[0].push(key);
            this.option.dataset.source[1].push(data[key].totalPeople);
            this.option.dataset.source[2].push(data[key].payCount);
            this.option.dataset.source[3].push(data[key].unpaidAmountCount);
          }
        }
      })
    }
  },
}
</script>

<style lang='less'>
  .studentSituation {
    width: 100%;
    display: flex;
    .studentSituation-cont-left {
      flex: 2.5;
      height: 422px;
      background: #FFFFFF;
      border-radius: 6px;
      margin-right: 20px;
      box-sizing: border-box;
      padding: 30px;
      .studentSituation-cont-title {
        font-size: 26px;
        font-weight: 400;
        color: #16222E;
        margin-bottom: 20px;
      }
      .stu-cont {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .stu-cont-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 40px;
          & > img {
            width: 23px;
            height: 23px;
          }
          .stu-common {
            width: 70px;
            font-size: 14px;
            font-weight: 400;
            color: #363840;
            margin-right: 10px;
          }
          .stu-num {
            width: 170px;
            font-size: 36px;
            font-weight: 400;
            color: #363840;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .studentSituation-cont-right {
      flex: 7.5;
      box-sizing: border-box;
      padding: 30px;
      background-color: #fff;
      .chart {
        height: 350px;
        max-width: 1200px;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
</style>