<template>
  <div class="index">
    <el-scrollbar class="elScroll">
      <studentSituation></studentSituation>
      <div class="coursewareResources">
        <div class="resource-title">课件资源情况</div>
        <div class="resource-cont">
          <div class="res-item">
            <div class="res-item-num">{{coursewareResources.courseCount}}</div>
            <div class="res-item-cont">
              <img src="@/assets/img/curriculumNum.png" alt="">
              <span>课程数量</span>
            </div>
          </div>
          <div class="res-item">
            <div class="res-item-num">{{coursewareResources.coursewareCount}}</div>
            <div class="res-item-cont">
              <img src="@/assets/img/coursewareNum.png" alt="">
              <span>课件数量</span>
            </div>
          </div>
          <div class="res-item">
            <div class="res-item-num">{{coursewareResources.bookCount}}</div>
            <div class="res-item-cont">
              <img src="@/assets/img/ebookNum.png" alt="">
              <span>教辅材料数量</span>
            </div>
          </div>
          <div class="res-item">
            <div class="res-item-num">{{coursewareResources.examTotal}}</div>
            <div class="res-item-cont">
              <img src="@/assets/img/testPaperNum.png" alt="">
              <span>试卷数量</span>
            </div>
          </div>
          <div class="res-item">
            <div class="res-item-num">{{coursewareResources.questionNum}}</div>
            <div class="res-item-cont">
              <img src="@/assets/img/subjectNum.png" alt="">
              <span>题目数量</span>
            </div>
          </div>
        </div>
      </div>
      <div class="echarts-school-ranking">
        <div class="echarts-school echart-show">
          <schoolDistribution></schoolDistribution>
        </div>
        <div class="echarts-ranking echart-show">
          <paymentRanking></paymentRanking>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import studentSituation from './webIndex/studentSituation'
import schoolDistribution from './webIndex/schoolDistribution'
import paymentRanking from './webIndex/paymentRanking'
export default {
  name: 'index',
  components: {
    studentSituation,
    schoolDistribution,
    paymentRanking
  },
  data() {
    return {
      coursewareResources: {}
    }
  },
  mounted() {
    this.getCourseResources();
  },
  methods: {
    getCourseResources() {
      this.$request.getCourseResources().then(res => {
        if(res.data.code == 0) {
          this.coursewareResources = res.data.data;
        }
      })
    }
  },
}
</script>

<style lang="less">
  .index {
    width: 100%;
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .coursewareResources {
      margin: 20px 0;
      box-sizing: border-box;
      padding-top: 30px;
      background-color: #fff;
      .resource-title {
        font-size: 26px;
        font-weight: 400;
        color: #16222E;
        margin-left: 30px;
      }
      .resource-cont {
        display: flex;
        justify-content: space-between;
        .res-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 33px 0;
          border-right: 1px solid #EFEFEF;
          &:nth-last-of-type(1) {
            border: 0 none;
          }
          .res-item-num {
            font-size: 40px;
            font-weight: 400;
            color: #363840;
          }
          .res-item-cont {
            display: flex;
            align-items: center;
          }
          img {
            width: 18px;
            height: 17px;
            margin-right: 6px;
          }
          span {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
    .echarts-school-ranking {
      display: flex;
      .echart-show {
        flex: 1;
        box-sizing: border-box;
        padding: 30px;
        background-color: #fff;
      }
      .echarts-school {
        margin-right: 20px;
      }
    }
  }
</style>