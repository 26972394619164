<template>
  <div class="schoolDistribution">
    <v-chart class="chart-school" :option="option" />
  </div>
</template>

<script>
export default {
  name: 'schoolDistribution',
  data() {
    return {
      option: {
        title: {
          show:true,
          text: '学校省份分布'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        legend: {
          bottom: '1%',
          left: 'center'
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '45%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              // { value: 1048, name: '广东' },
              // { value: 735, name: '江西' },
              // { value: 580, name: '北京' },
              // { value: 484, name: '西藏' },
              // { value: 300, name: '新疆' }
            ]
          }
        ]
      }
    }
  },
  mounted() {
    this.getSchoolDistribution();
  },
  methods: {
    getSchoolDistribution() {
      this.$request.getSchoolDistribution().then(res => {
        if(res.data.code == 0) {
          let data = res.data.data;
          for(let key in data) {
            this.option.series[0].data.push({
              value: data[key],
              name: key
            })
          }
        }
      })
    }
  },
}
</script>

<style lang='less'>
  .schoolDistribution {
    .chart-school {
      height: 350px;
      max-width: 800px;
      display: inline-block;
    }
  }
</style>