<template>
  <div class="paymentRanking">
    <v-chart class="chart-ranking" :option="option" />
  </div>
</template>

<script>
export default {
  name: 'paymentRanking',
  data() {
    return {
      option: {
        title: {
          show:true,
          text: '各校缴费率排行TOP 10'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            // data: ['广东', '江西', '北京', '湖南', 'Fri', 'Sat', 'Sun'],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
            formatter: function (value) {
              return value.length > 3 ? value.substring(0, 3) + '...' : value
            }
          }
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: 100,
            axisLabel: {
              formatter: '{value}%'
            }
          }
        ],
        series: [
          {
            name: '缴费率',
            type: 'bar',
            barWidth: '60%',
            tooltip: {
              valueFormatter: function (value) {
                return value + '%';
              }
            },
            data: []
            // data: [10, 52, 200, 334, 390, 330, 220]
          }
        ]
      }
    }
  },
  mounted() {
    this.getSchoolPaymentRates();
  },
  methods: {
    getSchoolPaymentRates() {
      this.$request.getSchoolPaymentRates().then(res => {
        if(res.data.code == 0) {
          let data = res.data.data.slice(0, 10);
          data.forEach(element => {
            let payProportion = element.payProportion.substring(0, element.payProportion.length - 1);
            this.option.xAxis[0].data.push(element.name);
            this.option.series[0].data.push(payProportion);
          });
        }
      })
    }
  },
}
</script>

<style lang='less'>
  .paymentRanking {
    .chart-ranking {
      height: 350px;
      max-width: 800px;
      display: inline-block;
    }
  }
</style>